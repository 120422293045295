<template>
  <div>
    <el-card>
      <div class="flexRow flex-wrap">
        <div class="flexRow" style="margin:0px 20px 10px 0px">
          <div class="label">关键字：</div>
          <el-input v-model="keywords" placeholder="活动名称" style="width:220px"></el-input>
        </div>
        <div class="flexRow" style="margin:0px 20px 10px 0px">
          <div class="label">活动状态：</div>
          <el-select v-model="state" placeholder="">
            <el-option v-for="v in statelist" :key="v.value" :value="v.value" :label="v.label"></el-option>
          </el-select>
        </div>
        <el-button type="primary" style="margin:0px 20px 10px 0px" @click="query">查询</el-button>
        <buttonPermissions datas="pointsCodePush" style="margin:0px 20px 10px 0px">
          <el-button type="primary" @click="toedit({},0)">添加</el-button>
        </buttonPermissions>
        <!-- <buttonPermissions datas="pointsCodeExtend" style="margin:0px 20px 10px 0px"> -->
        <el-button type="primary" @click="extend" style="margin:0px 20px 10px 0px">生成兑换链接</el-button>
        <!-- </buttonPermissions> -->
        <el-button type="primary" style="margin:0px 20px 10px 0px" @click="exChangeRules">兑换规则</el-button>
        <!-- <el-button type="primary" style="margin:0px 20px 10px 0px">生成兑换链接</el-button> -->
      </div>
      <div>
        <el-table :data="tablelist" v-loading="tableloading">
          <el-table-column prop="" label="活动名称">
            <template slot-scope="scope">
              <div class="ellipsis-two">
                {{scope.row.Name}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="活动时间">
            <template slot-scope="scope">
              <div>
                <div v-if="scope.row.IsPermanentActivity">永久有效</div>
                <div v-else>
                  <div>
                    {{scope.row.StartTime}}至
                  </div>
                  <div>
                    {{scope.row.EndTime}}
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="IsOpenValue" label="活动状态">
            <template slot-scope="scope">
              <div>
                {{scope.row.State==0?'未开始':(scope.row.State==1?'进行中':'已结束')}}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="发放总量">
            <template slot-scope="scope">
              <div>
                <el-button type="text" @click="todata(scope.row)">{{scope.row.SendCount}}</el-button>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="ExchangedCount" label="已兑换数量"></el-table-column>
          <el-table-column prop="LeaveCount" label="剩余数量"></el-table-column>
          <el-table-column prop="AddTime" label="创建时间"></el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <div>
                <buttonPermissions datas="pointsCodeEdit" style="margin:0px 10px 10px 0px" v-if="scope.row.State==0||scope.row.State==1">
                  <el-button type="text" @click="toedit(scope.row,0)">编辑</el-button>
                </buttonPermissions>

                <!-- <buttonPermissions datas="pointsCodeExtend" style="margin:0px 10px 10px 0px" v-if="scope.row.State==0||scope.row.State==1">
                  <el-button type="text" @click="extend(scope.row)">推广</el-button>
                </buttonPermissions> -->
                
                <buttonPermissions datas="pointsCodeStop" style="margin:0px 10px 10px 0px" v-if="scope.row.State==0||scope.row.State==1">
                  <el-button type="text" @click="stop(scope.row)">使终止</el-button>
                </buttonPermissions>
                
                <buttonPermissions datas="pointsCodeCopy" style="margin:0px 10px 10px 0px" v-if="scope.row.State==0||scope.row.State==1">
                  <el-button type="text" @click="toedit(scope.row,3)">复制</el-button>
                </buttonPermissions>
                
                <!-- <buttonPermissions2 :datas="['pointsCodeStop','pointsCodeCopy']" v-if="scope.row.State==0||scope.row.State==1">
                  <el-dropdown>
                    <el-button type="text">
                      更多<i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <div class="flexCol">
                        
                        <buttonPermissions datas="pointsCodeStop">
                          <el-dropdown-item>
                            <el-button type="text" @click="stop(scope.row)">使终止</el-button>
                          </el-dropdown-item>
                        </buttonPermissions>

                        <buttonPermissions datas="pointsCodeCopy">
                          <el-dropdown-item>
                            <el-button type="text" @click="toedit(scope.row,3)">复制</el-button>
                          </el-dropdown-item>
                        </buttonPermissions>

                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </buttonPermissions2> -->

                <buttonPermissions datas="pointsCodeLook" style="margin:0px 10px 10px 0px" v-if="scope.row.State==2">
                  <el-button type="text" @click="toedit(scope.row,1)">查看</el-button>
                </buttonPermissions>

                <buttonPermissions datas="pointsCodeCopy" style="margin:0px 10px 10px 0px" v-if="scope.row.State==2">
                  <el-button type="text" @click="toedit(scope.row,3)">复制</el-button>
                </buttonPermissions>

                <buttonPermissions datas="pointsCodeDel" style="margin:0px 10px 10px 0px" v-if="scope.row.State==2">
                  <el-button type="text" style="color:#f56c6c" @click="del(scope.row)">删除</el-button>
                </buttonPermissions>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <div style="margin-top:10px;text-align:right" v-if="total">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[10, 20, 30, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
          </el-pagination>
        </div>
      </div>
    </el-card>

    
		<batch-cods :weiCodeVisivble="weiCodeShow" :weiCodeData="weiCodeData" @cancel="weiCodeShow = false">
		</batch-cods>

    <el-dialog :visible.sync="changerulesShow" title="设置兑换规则" v-loading="changerulesloading" width="550px"
     custom-class="dialog-body-paddingTop-10" top="40vh">
      <div style="display:flex">
        <div class="label" style="line-height:18px">错误次数限制：</div>
        <div>
          <el-checkbox v-model="IsOpenErrorLimit" :label="true">开启【当天仅可输错3次】规则</el-checkbox>
          <div class="grayfont" style="margin-top:5px">开启后，每个客户当天若输错3次，将要等到第二天才可继续兑换</div>
        </div>
      </div>
      <div slot="footer">
        <el-button @click="changerulesShow=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import buttonPermissions from '@/components/buttonPermissions';
import buttonPermissions2 from '@/components/buttonPermissions2';
import batchCods from "@/components/bathCodes"
import {
  activityPointCodelist,
  activityPointCodedelete,
  activityPointCodeclose,
  activityPointCodeerrorLimitInfo,
  activityPointCodeerrorLimitInfoSave
} from "@/api/sv3.0.0"
export default {
  components:{
    buttonPermissions,
    buttonPermissions2,
    batchCods
  },
  data () {
    return {
      keywords:'',
      state:null,
      statelist:[
        {value:null,label:'全部'},
        {value:0,label:'未开始'},
        {value:1,label:'进行中'},
        {value:2,label:'已结束'},
      ],
      tablelist:[],
      tableloading:false,
      currentPage:1,
      pagesize:20,
      total:0,
			weiCodeData: {},
			weiCodeShow: false,
      changerulesShow:false,
      changerulesloading:false,
      IsOpenErrorLimit:false,
    }
  },
  mounted () {
    this.gettablelist()
  },
  methods: {
    async save(){
      try{
        this.changerulesloading = true
        let res = await activityPointCodeerrorLimitInfoSave({
          IsOpenErrorLimit:this.IsOpenErrorLimit
        })
        if(res.IsSuccess){
          this.$message.success('操作成功')
          this.changerulesShow = false
        }
      }finally{
        this.changerulesloading = false
      }
    },
    exChangeRules(){
      this.changerulesShow = true
      this.getinfo()
    },
    async getinfo(){
      try{
        this.changerulesloading = true
        let res = await activityPointCodeerrorLimitInfo()
        if(res.IsSuccess){
          this.IsOpenErrorLimit = res.Result.IsOpenErrorLimit
        }
      }finally{
        this.changerulesloading = false
      }
    },
    extend(row){
      this.weiCodeShow = true
      this.weiCodeData = {
        Page: 'pageA/pages/receive-point/receive-point',
				Scene: `1`
      }
    },
    stop(row){
      this.$confirm('活动终止后，你将不可再对这场活动进行编辑，客户也不可继续兑换积分（已兑换积分不影响）是否确认使终止？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await activityPointCodeclose({
            Id:row.Id
          })
          if(res.IsSuccess){
            this.$message.success('操作成功')
          }
        }finally{
          this.gettablelist()
        }
      }).catch(() => {});
    },
    del(row){
      this.$confirm('是否确认删除本场活动？删除后不可恢复', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '关闭',
        type: 'warning'
      }).then(async () => {
        try{
          let res = await activityPointCodedelete({
            Id:row.Id
          })
          if(res.IsSuccess){
            this.$message.success('删除成功')
            
          }
        }finally{
          this.gettablelist()
        }
      }).catch(() => {});
    },
    toedit(row,type){
      this.$router.push({
        path:'/Customer/pointsCode/edit',
        query:{
          Id:row.Id,
          type:type
        }
      })
    },
    todata(row){
      this.$router.push({
        path:'/Customer/pointsCode/data',
        query:{
          Id:row.Id,
          Name:encodeURIComponent(row.Name)
        }
      })
    },
    handleSizeChange(e){
      this.currentPage = 1
      this.pagesize = e
      this.gettablelist()
    },
    handleCurrentChange(e){
      this.currentPage = e
      this.gettablelist()
    },
    query(){
      this.currentPage = 1
      this.gettablelist()
    },
    async gettablelist(){
      try{
        this.tableloading = true
        let res = await activityPointCodelist({
          KeyWords:this.keywords,
          ActivityState:this.state,
          Skip:(this.currentPage-1)*this.pagesize,
          Take:this.pagesize
        })
        if(res.IsSuccess){
          this.tablelist = res.Result.Results
          this.total = res.Result.Total
        }
      }finally{
        this.tableloading = false
      }
    }
  }
}
</script>

<style lang="less" scoped>
.flexRow{
  display: flex;
  align-items: center;
}
.flex-wrap{
  flex-wrap: wrap;
}
.label{
  font-size: 14px;
  color: #606266FF;
  white-space: nowrap;
  flex-shrink: 0;
  font-weight: bold;
}
.flexCol{
  display: flex;
  flex-direction: column;
}

.grayfont{
  font-size: 14px;
  color: #999999;
  font-weight: 400;
  line-height: 1.5;
}
</style>